
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  inject,
  onMounted,
  PropType,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import { AxiosError } from 'axios';
import { ApmBase } from '@elastic/apm-rum';

/** Interfaces */
import {
  AttributesGeneralI,
  LinceObligationI,
  RepositoryObligationI,
} from '@/interfaces/general.interface';

/**Utils */
import {
  closeSwal,
  showSwalError,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';
import { handleCatchErrorCustom } from '@/utils/general-utils';

/**Composables */
import { useElasticTransactionApm } from '@/composition/useElasticTransactionApm';

export default defineComponent({
  name: 'Payments',
  props: {
    client: {
      type: Object as PropType<AttributesGeneralI>,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    LastPaymentDetail: defineAsyncComponent(
      () => import('@/components/cards/LastPaymentDetail.vue')
    ),
    ProcessPayment: defineAsyncComponent(
      () => import('@/modules/payments/components/ProcessPayment.vue')
    ),
    Loader: defineAsyncComponent(
      () => import('@/components/loader/Loader.vue')
    ),
  },
  setup() {
    const store = useStore();
    const $apm = inject('$apm') as ApmBase;

    const { customTransactioncApm, endTransactionApm } =
      useElasticTransactionApm();

    const processData = ref([]);

    const identification = computed(() => {
      return store.getters?.getAuthUser?.identification;
    });

    const openLinceObligations = computed((): LinceObligationI[] => {
      const obligations: LinceObligationI[] =
        store.state.dataGeneral?.lince_obligations || [];

      return obligations.length > 0 ? obligations : [];
    });

    const timestamp = computed(() => {
      return store.getters.getTimestamp;
    });

    const paymentProcessData = async () => {
      showSwalLoading();
      try {
        const response = await store.dispatch(
          'payment/getProcessPayments',
          identification.value
        );
        if (response.payments_process) {
          processData.value = response.payments_process;
          closeSwal();
        } else {
          showSwalError('Se produjo un error al obtener los pagos aplicados');
        }
      } catch (error) {
        handleCatchErrorCustom(
          error as AxiosError,
          'Se produjo un error al obtener los pagos aplicados'
        );
      }
    };

    const openRepositoryObligations = computed((): RepositoryObligationI[] => {
      const obligations: RepositoryObligationI[] =
        store.state.dataGeneral?.repository_obligations || [];

      return obligations.length > 0 ? obligations : [];
    });

    const hasCurrentObligationsInLince = computed<boolean>(() => {
      return (
        openLinceObligations.value.length > 0 ||
        openRepositoryObligations.value.length > 0
      );
    });

    onMounted(() => {
      customTransactioncApm($apm, 'click - consultar-pagos', 'custom');
      paymentProcessData();
      endTransactionApm();
    });

    return {
      timestamp,
      processData,
      openLinceObligations,
      openRepositoryObligations,
      hasCurrentObligationsInLince,
    };
  },
});
